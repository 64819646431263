<template>
    <div class="byme row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div>
                <div class="bg-transparent text-center">
                    <!-- <content-placeholders v-show="products.length == 0" class="m-5">
                        <content-placeholders-heading :img="true" />
                        <content-placeholders-text :lines="2" />
                    </content-placeholders>
                    <div v-show="products.length > 0" > -->
                    <div>
                        <div class="row flex my-3" style="text-align:left">
                            <div class="px-5 py-3">
                                <h1 class="py-3">Bookabook Byme</h1>
                                <p>“Bookabook by me” adalah acara yang mendorong orang tua untuk membuat buku cerita untuk anak-anak mereka. Dalam acara ini, orang tua dapat membeli set alat gambar dan membuat buku cerita yang akan dicetak dalam format fisik. Buku cerita juga dapat diunggah ke bookabook.id untuk dinikmati oleh orang lain.</p>
                                <a :href="(domain == 'byme.bookabook.id') ? 'https://bookabook.id/product/byme-kit' : '/product/byme-kit'" @click="handleScroll()"><base-button type="primary">Beli Byme Kit</base-button></a>
				
                                
                            </div>
                            <div class="px-3 py-3"><img class="illus" src="/img/byme/byme-logo-sketch.png"></div>
                            
                        </div>
                        <div class="row flex my-3" style="text-align:left">
                            <div class="px-3 py-3"><img class="illus" src="/img/byme/byme-photo-1.png"></div>
                            <div class="px-5 py-3">
                                <h1 class="py-3">Kegunaan menulis untuk anak-anak</h1>
                                <p>Menulis memungkinkan anak-anak untuk mengekspresikan kreativitas dan imajinasi mereka dengan cara yang unik. Dengan menulis, anak-anak dapat menciptakan dunia fiksi dan karakter yang menarik.</p>
                            </div>                            
                        </div>
                        <div class="row flex my-3 accent" style="text-align:left">
                            <div class="px-5 py-3">
                                <h1 class="py-3">Tentang Byme</h1>
                                <p>“Bookabook by me” adalah acara yang mendorong orang tua untuk membuat buku cerita untuk anak-anak mereka. Dalam acara ini, anak-anak dapat menggambar atau menulis cerita mereka sendiri, yang nantinya akan dijadikan buku yang dipersonalisasi dengan ilustrasi dan cerita mereka sendiri. Acara ini memberikan kesempatan bagi anak-anak untuk menunjukkan kreativitas mereka dan menghasilkan buku yang unik dan bermakna.</p>
                            </div>                            
                            <div class="px-3 py-5"><img class="illus" src="/img/byme/byme-photo-2.png"></div>
                        </div>
                        <h1 class="my-5">ByMe Guide</h1>
                        <div class="row my-3">
                            <div class="col-sm-12 col-md-6"><img src="/img/byme/byme-guide-1.png"></div>
                            <div class="col-sm-12 col-md-6"><img src="/img/byme/byme-guide-2.png"></div>
                        </div>
                        <!-- <h1 class="my-5">What our customers said about us!</h1>
                        <div class="row my-3">
                            <div class="col-sm-12 col-md-12"><img src="/img/home/testimony.png" style="max-width:100%"></div>
                        </div>                         -->
                        <div class="row flex my-3" style="text-align:left">
                            <div class="px-3 py-5"><img class="illus" src="/img/byme/byme-kit.png"></div>
                            <div class="px-5 py-3">
                                <h1 class="py-3">Sudah Punya Byme Kit?</h1>
                                <p>Jika kamu sudah memiliki Byme Kit atau sudah membuat cerita kamu sendiri, kamu bisa membuat hasil kreasimu dan ceritamu menjadi buku loh!!!
                                Caranya dengan scan buku kamu lalu dibuat format PDF dan upload di design.Bookabook.id lalu tunggu bukumu akan datang</p>
                                <div><a href="https://bookabook.id/byme-upload"><base-button type="primary">Upload di sini!</base-button></a></div>   
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'bookabook',
    data() {
    return {
        domain: 'bookabook.id',
        }
    },
    created(){
        this.domain = window.location.hostname;
    },
    computed: {
        products() {
            return this.$store.state.products;
        }
    },
    methods: {
    scrollToProducts() {
      this.$refs["products"].scrollIntoView({ behavior: "smooth" })
    },
    scrollToTop() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
  },
}

</script>
