<template>
    <div class="create-post">
      <base-header type="gradient-success" class="pb-3 pt-1 pt-md-8">
      <!-- <h1 style="color: white" v-if="!loading">{{ productName }}</h1> -->
      </base-header>

      <div class="mt--5 col-xl-12 order-xl-1">
        <card shadow style="padding:0">
          <content-placeholders v-show="loading" class="m-5">
              <content-placeholders-heading :img="true" />
              <content-placeholders-text :lines="2" />
              <content-placeholders-heading :img="true" />
              <content-placeholders-text :lines="2" />
            </content-placeholders>
            <div slot="header" class="bg-white border-0" v-show="!loading">
                <div class="row align-items-center">
                    <div class="col-4">
                        <h3 class="mb-0">{{ productName }}</h3>
                    </div>
                    <div class="col-8 text-right item-menu">
                      <div @click="duplicateModel()" class="btn btn-sm btn-primary">Duplicate</div>
                      <div @click="deleteModel()" class="btn btn-sm btn-danger">Delete</div>
                    </div>
                </div>
            </div>
            <template>
                <form @submit.prevent v-show="!loading">
                    <div class="container">
                      <div :class="{ invisible: !error }" class="err-message">
                        <p><span>Error:</span>{{ this.errorMsg }}</p>
                      </div>
                      <div class="pl-lg-4">
                        <div class="row">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Voucher Code"
                                          placeholder="YOURVOUCHERCODE99"
                                          input-classes="form-control-alternative"
                                          v-model="model.code"
                              />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <div class="form-control-label mb-3">Status</div>
                              <div style="display:inline-block">
                                <div class="custom-control custom-radio mb-2">
                                  <input name="custom-radio-1" :checked="(model.active)" class="custom-control-input" id="customRadio1-0" type="radio" @click="setActive(true)">
                                  <label class="custom-control-label" for="customRadio1-0">active</label>
                                </div>
                                <div class="custom-control custom-radio mb-2">
                                  <input name="custom-radio-1" :checked="(!model.active)" class="custom-control-input" id="customRadio1-1" type="radio" @click="setActive(false)">
                                  <label class="custom-control-label" for="customRadio1-1">inactive</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                            <div class="form-control-label mb-3">Type</div>
                              <div class="custom-control custom-radio mb-2" v-for="type, index in voucherTypes" :key="index">
                                <input name="custom-radio-2" :checked="(model.type == type.toLowerCase().replaceAll(' ','_'))" class="custom-control-input" :id="'customRadio2-'+index" type="radio"  @click="setType(type.toLowerCase().replaceAll(' ','_'))">
                                <label class="custom-control-label" :for="'customRadio2-'+index">{{ type }}</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <div class="form-control-label mb-3">Free Shipping</div>
                              <div style="display:inline-block">
                                <div class="custom-control custom-radio mb-2">
                                  <input name="custom-radio-3" :checked="(!model.free_shipping && model.type != 'free_shipping_only')" :disabled="(model.type == 'free_shipping_only')" class="custom-control-input" id="customRadio3-0" type="radio" @click="setFreeShipping(true)">
                                  <label class="custom-control-label" for="customRadio3-0">no</label>
                                </div>
                                <div class="custom-control custom-radio mb-2">
                                  <input name="custom-radio-3" :checked="(model.free_shipping)" class="custom-control-input" id="customRadio3-1" type="radio" @click="setFreeShipping(false)">
                                  <label class="custom-control-label" for="customRadio3-1">yes</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6" v-if="model.type=='discount_by_percentage' || model.type=='discount_by_value'">
                              <base-input alternative=""
                                          label="Value"
                                          placeholder="30% | 10000"
                                          input-classes="form-control-alternative"
                                          v-model="model.value"
                              />
                          </div>
                          <div class="col-lg-6">
                            <base-input alternative=""
                                            label="Minimum Item Quantity"
                                            placeholder="1"
                                            input-classes="form-control-alternative"
                                            v-model="model.minimumQty"
                                />
                          </div>
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Quota"
                                          placeholder="100 | -1 for unlimited"
                                          input-classes="form-control-alternative"
                                          v-model="model.quota"
                              />
                          </div>
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Expiry"
                                          placeholder="YYYY-MM-DDThh:mm:ss.000Z format | - for non expired"
                                          input-classes="form-control-alternative"
                                          v-model="model.expiry_date"
                              />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="form-group">
                              <div class="form-control-label mb-3">Data</div>
                              <div>Created Date: <b>{{ model.created_at }}</b></div>
                              <div>Used: <b>{{ orders.length }}</b> times</div>
                              <div v-if="orders.length>0">Total Commission: {{ totalCommission.toLocaleString('en-US', {
                                              style: 'currency',
                                              currency: 'IDR',
                                              }) }}</div>
                              <div v-if='orders.length>0'>
                                <div class="table-responsive">

                                  <content-placeholders v-show="orders.length == 0" class="m-5">
                                    <content-placeholders-heading :img="true" />
                                    <content-placeholders-text :lines="2" />
                                    <content-placeholders-heading :img="true" />
                                    <content-placeholders-text :lines="2" />
                                  </content-placeholders>

                                  <base-table class="table align-items-center table-flush mt-3"
                                              :class="type === 'dark' ? 'table-dark': ''"
                                              :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                              tbody-classes="list"
                                              :data="orders" v-show="orders.length > 0">
                                  <template slot="columns">
                                      <th>OrderID</th>
                                      <th>Name</th>
                                      <th>Items</th>
                                      <th>Status</th>
                                      <th>Subtotal</th>
                                      <th>Discount</th>
                                      <th>Total</th>
                                      <th>Commission</th>
                                      <th></th>
                                  </template>

                                  <template slot-scope="{row}">
                                      <th scope="row">
                                      <div class="media align-items-center clickable" @click="view(row.id)">
                                          <div class="media-body">
                                          <span class="name mb-0 text-sm">{{row.orderID}}</span>
                                          </div>
                                      </div>
                                      </th>
                                      <td>
                                        {{ row.name }}
                                      </td>
                                      <td class="item">
                                        <div v-for="product, index in row.cart" :key="index">
                                          <b>{{ product.productName }}</b>
                                          <span v-if="product.childname" style="color:gray"> for </span>
                                          <b>{{ product.childname }}</b>
                                        </div>
                                      </td>
                                      <td>
                                        <div style="padding: 2px;border-radius: 5px;text-align:center;" :class="{'text-white': true,
                                            'bg-warning': row.paymentStatus == 'Unpaid' || row.paymentStatus == null,
                                            'bg-success': row.paymentStatus == 'Paid' || row.paymentStatus,}
                                            ">{{(row.paymentStatus || "Unpaid")}}</div>
                                      </td>                                      
                                      <td class="price">
                                        {{ row.totalBeforeDiscount.toLocaleString('en-US', {
                                              style: 'currency',
                                              currency: 'IDR',
                                              }) }}
                                      </td>
                                      <td class="price">
                                        {{ row.discount.toLocaleString('en-US', {
                                              style: 'currency',
                                              currency: 'IDR',
                                              })  }}
                                      </td>
                                      <td class="price">
                                        {{ row.total.toLocaleString('en-US', {
                                              style: 'currency',
                                              currency: 'IDR',
                                              }) }}
                                      </td>
                                      <td class="price">
                                        {{ row.commission.toLocaleString('en-US', {
                                              style: 'currency',
                                              currency: 'IDR',
                                              })  }}
                                      </td>
                                  </template>

                                  </base-table>
                                  </div>

                                </div>

                            </div>
                          </div>
                        </div>
                    </div>
                      <div class="blog-actions">
                        <button class="btn btn-sm btn-primary" @click="updateModel">Save Changes</button>
                        <!-- <router-link class="router-button" :to="{ name: 'BlogPreview' }">Preview Changes</router-link> -->
                      </div>
                    </div>        
                </form>
            </template>
        </card>
      </div>
    </div>
  </template>
  
<script>
  // import { Timestamp } from "firebase/firestore";
  import "firebase/storage";
  import firebaseApp from "../firebase/firebaseInit";
  const db = firebaseApp.firestore();
  
  export default {
    name: "CreatePost",
    data() {
      return {
        id: null,
        file: null,
        error: null,
        errorMsg: null,
        loading: null,
        type: '',
        totalCommission: 0,
        voucherTypes: [
          'Discount By Percentage',
          'Discount By Value',
          'Free Lowest Price Product',
          'Free Shipping Only'],
        model: {
          name: '',
          voucher_type: '',
          img: null,
          expiry_date: Date.now(),
          created_at: Date.now(),
          free_shipping: false,
        },
        orders: [],
        productName: "",
        editorSettings: {
          modules: {
            imageResize: {},
          },
        },
      };
    },
    async mounted() {
      this.loading = true;
      this.routeID = this.$route.params.id;
      this.getModel();
    },
    methods: {
      setActive(status){
        this.model.active = status;
      },
      setFreeShipping(status){
        this.model.free_shipping = status;
      },
      setType(type){
        this.model.type = type;
        if(type=='free_shipping_only') this.model.free_shipping = true;
      },
      
      async duplicateModel() {
        let voucherNewCode = prompt("Please enter your product name:", this.model.code + "-COPY");
        if (voucherNewCode == null || voucherNewCode == "") {
          alert("User cancelled the prompt.");
        } else {
          this.loading = true;
          alert('Duplicating product to ' + voucherNewCode + ', you will be redirected to the new product');
          let duplicateModel = JSON.parse(JSON.stringify(this.model));
          duplicateModel.code = voucherNewCode;
          duplicateModel.created_at = new Date();
          console.log(duplicateModel);

          const dataBase = await db.collection("vouchers").doc();
          await dataBase.set(duplicateModel).then(
            () => {
              // console.log(result);
              this.$router.push({ name: "Edit Voucher", params: { id: dataBase.id } });
              this.loading = false;
              this.routeID = dataBase.id;
              this.getModel();
            }
          );
        }
      },

      async deleteModel() {
        if (confirm("This action can't be undone, if you want to remove the book from customer display, you can update the status to archived instead. Are you sure you want to delete this product?") == false) {
          console.log("User cancelled the prompt.");
        } else {
          this.loading = true;
          await db.collection("vouchers").doc(this.routeID).delete().then(
            () => {
              alert('your product has sucessfully been deleted, you will be redirected to vouchers list page');
              this.$router.push({ name: "vouchers"});
            }
          );
        }
      },

      async getModel() {
        const snapshot = await db.collection("vouchers").doc(this.routeID).get();
        
        // console.log(snapshot.data());
        this.model = snapshot.data();
        if(snapshot.data().expiry_date != null && snapshot.data().expiry_date != '-') this.model.expiry_date = new Date(snapshot.data().expiry_date.seconds * 1000).toISOString();
        else this.model.expiry_date = '-';
        this.model.created_at = new Date(snapshot.data().created_at.seconds * 1000).toISOString();
        const ordersSnapshot = await db.collection("orders").where('voucherId','==',this.routeID).get();
        this.totalCommission = 0;
        ordersSnapshot.forEach((orderRef) => {
          const order = orderRef.data();
          order.commission = (order.paymentStatus == 'Paid') ? 0.1 * order.total : 0;
          this.totalCommission += order.commission;
          this.orders.push(order);
        });
        console.log(this.orders);
        this.used = this.orders.length;
        this.loading = false;
      
      },
  
      async updateModel() {
        const dataBase = await db.collection("vouchers").doc(this.routeID);
        if (this.model.code.length !== 0) {
          console.log('updating model', this.model);
          if(this.model.expiry_date != '-')
            this.model.expiry_date = new Date(this.model.expiry_date);
          
          this.model.created_at = new Date();
          await dataBase.update(this.model);
          this.loading = false;
          alert('Your Data has been Updated');
          this.getModel();
          // this.$router.push({ name: "Edit Voucher", params: { id: dataBase.id } });
          return;
        } else {
          alert('name cannot be empty');
        }
        this.error = true;
        this.errorMsg = "Please ensure Blog Title & Blog Post has been filled!";
        setTimeout(() => {
          this.error = false;
        }, 5000);
        return;
      },
    },
    computed: {
      profileId() {
        return this.$store.state.profileId;
      },
      productPhotoName() {
        return this.$store.state.productPhotoName;
      },
      productDescription: {
        get() {
          return this.$store.state.productDescription;
        },
        set(payload) {
          this.$store.commit("newProduct", payload);
        },
      },
    },
  };
  </script>
  
  <style lang="scss">
  .item-menu btn {
    display: inline-block;
  }
  .create-post {
    position: relative;
    height: 100%;
  
    button {
      margin-top: 0;
    }
  
    .router-button {
      text-decoration: none;
      color: #fff;
    }
  
    .button,
    .router-button {
      transition: 0.5s ease-in-out all;
      align-self: center;
      font-size: 14px;
      cursor: pointer;
      border-radius: 20px;
      padding: 12px 24px;
      color: #fff;
      background-color: #303030;
      text-decoration: none;
  
      &:hover {
        background-color: rgba(48, 48, 48, 0.7);
      }
    }
  
    .container {
      position: relative;
      height: 100%;
      padding: 10px 25px 60px;
    }
  
    // error styling
    .invisible {
      opacity: 0 !important;
      height: 0;
    }
  
    .err-message {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      color: #fff;
      margin-bottom: 10px;
      background-color: #303030;
      opacity: 1;
      transition: 0.5s ease all;
  
      p {
        font-size: 14px;
      }
  
      span {
        font-weight: 600;
      }
    }
  
    .blog-info {
      display: flex;
      margin-bottom: 20px;
  
      input:nth-child(1) {
        min-width: 300px;
      }
  
      input {
        transition: 0.5s ease-in-out all;
        padding: 10px 4px;
        border: none;
        border-bottom: 1px solid #303030;
  
        &:focus {
          outline: none;
          box-shadow: 0 1px 0 0 #303030;
        }
      }
  
      .upload-file {
        flex: 1;
        margin-left: 16px;
        position: relative;
        display: flex;
  
        input {
          display: none;
        }
  
        .preview {
          margin-left: 16px;
          height: 20pt;
          text-transform: initial;
        }
  
        span {
          font-size: 12px;
          margin-left: 16px;
          align-self: center;
        }
      }
    }
  
    .editor {
      height: 60vh;
      display: flex;
      flex-direction: column;
  
      .quillWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
  
      .ql-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: scroll;
      }
  
      .ql-editor {
        padding: 20px 16px 30px;
      }
    }
  
    .blog-actions {
      margin-top: 32px;
  
      button {
        margin-right: 16px;
      }
    }
  }
  </style>
  