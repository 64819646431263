<template>
  <div class="create-post">
    <base-header type="gradient-success" class="pb-3 pt-1 pt-md-8">
  </base-header>
  
  <div class="mt--5 col-xl-12 order-xl-1">
    <card shadow style="padding:0">
      <content-placeholders v-show="loading" class="m-5">
        <content-placeholders-heading :img="true" />
        <content-placeholders-text :lines="2" />
        <content-placeholders-heading :img="true" />
        <content-placeholders-text :lines="2" />
      </content-placeholders>
      <div slot="header" class="bg-white border-0" v-show="!loading">
        <div class="row align-items-center">
          <div class="col-8">
            <h3 class="mb-0">View Order</h3>
          </div>
          <div class="col-4 text-right">
            <div class="dropdown">
              <div @click="deleteModel()" class="btn btn-sm btn-danger">Delete</div>
                <button class="btn btn-primary btn-sm"
                @click="toggleMenu()"
              type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right"
              :style="{display: (this.showMenu) ? 'block' : 'none'}"
              aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" @click="updatePayment()">Update Payment to <b>{{ (this.model.paymentStatus != 'Paid') ? 'PAID': 'UNPAID' }}</b></a>
                <a class="dropdown-item" href="#" @click="updateOrder()">Update Order to <b>{{ (this.model.orderStatus != 'Fulfilled') ? 'FULFILLED': 'UNFULFILLED' }}</b></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      
      <form @submit.prevent v-show="!loading">
        <div class="container">
          <div class="row align-items-top">
            <div v-show="error" class="err-message">
              <p><span>Error:</span>{{ this.errorMsg }}</p>
            </div>
            <div class="col-lg-6 col-md-12">
              <!-- <pre style="padding: 10px;background: lightgray;border-radius: 10px;">{{ model }}</pre> -->
              <h6 class="heading-small text-muted mb-1">Products</h6>
              <hr class="my-1" />
              <div class="cart item-container">
                <div v-for="product, index in model.cart" :key="index">
                            <div class="flex-container">
                              <div class="flex-left">
                                    <div :style="{ backgroundImage: (product.productImage) 
                                                ? 'url('+product.productImage+')' : 'url(/img/' + product.productUrlCode + '/samplecover.jpg)' }"
                                        class="cartItem">
                                    </div>
                                </div>
                                <div class="flex-mid" style="flex-grow:1">
                                    <small>
                                        <b>{{ product.productName }}</b>
                                        <div>for: <b>{{ product.childname }}</b></div>
                                        <div>with message:</div>
                                        <div><b>{{ product.message }}</b></div>
                                        <div class="bookablue">{{ product.productPrice.toLocaleString('en-US', {
                                          style: 'currency',
                                            currency: 'IDR',
                                            }) }}</div>
                                    </small>
                                   
                                </div>   
                            </div>
                            <h5 v-if="product.selected_child" class="mt-2">Personalization:</h5> 
                            <div v-if="product.selected_child" class="flex-container">
                                <div class="mx-1 personalization"
                                    :style="{ backgroundImage: 'url('+ product.selected_child + ')' }">
                                </div>
                                <span style="padding:7px">{{ product.selected_child ? product.selected_child.split('/')[product.selected_child.split('/').length-1].split('.')[0] : '' }}</span>
                                <div v-if="product.selected_candle" class="mx-1 personalization"
                                    :style="{ backgroundImage: 'url('+ product.selected_candle + ')' }">
                                </div>
                                <div v-if="product.selected_cover" class="mx-1 personalization"
                                    :style="{ backgroundImage: 'url('+ product.selected_cover + ')' }">
                                </div>
                                <div v-if="product.selected_child2" class="mx-1 personalization"
                                    :style="{ backgroundImage: 'url('+ product.selected_child2 + ')' }">
                                </div>    
                            </div>              
                            <hr class="my-3"/>
                        </div>
              </div>
              <h6 class="heading-small text-muted mb-1 mt-4">Shipping Detail</h6>
              <hr class="my-1" />
              <small>
                <div>Courier: {{ model.selectedCourier.courier }}</div>
                <div>Service: {{ model.selectedCourier.service }}</div>
                <div>Duration: {{ model.selectedCourier.duration }}</div>        
                <div>Price: {{ model.selectedCourier.price.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'IDR',
                      }) }}</div>
                <div v-if="model.shipping_data">No Resi.: {{ model.shipping_data.tracking_info.airwaybill }}</div>
                <div v-if="model.shipping_data">Shipdeo ID: {{ model.shipping_data._id }}</div>
                <div v-if="model.shipping_data">
                  <div>History Tracking:</div>
                  <div v-for="history, index in model.shipping_data.order_histories" :key="index">
                    <span class="px-2"> | </span> {{ moment(history.status_date.toLocaleString()).format('YYYY-MM-DD, HH:mm:ss') }}, {{ orderHistoryStatus(history.status)}}</div>
                </div>
              
              </small>        
              <hr class="mt-4" />
              <div v-if="model.paymentStatus == 'Paid'">
                <div @click="checkShipping(true)" class="btn btn-primary mb-1">Check Shipping</div>
                <div v-if="model.shipping_data" @click="getShippingLabel()" class="btn btn-primary mb-1">Shipping Label</div>
                <div v-if="(model.orderStatus == 'Unfulfilled' || model.orderStatus == null)" @click="createShippingOrder()" class="btn btn-primary mb-1">Create Shipping Order</div>
                <div></div>
                <div @click="requestPickup('pickup')" v-if="model.orderStatus == 'ENTRY'" class="btn btn-warning mb-1">Request Pickup</div>
                <div @click="requestPickup('dropoff')" v-if="model.orderStatus == 'ENTRY'" class="btn btn-warning mb-1">Dropoff Order</div>
                <!-- <div class="card my-3" v-if="shipping_data">
                  <div><b>Order Number:</b> {{ shipping_data.order_number }}</div>
                  <div><b>Item:</b> {{ shipping_data.item_summary }}</div>
                  <div><b>Tracking Info:</b> {{ shipping_data.tracking_info }}</div>
                </div> -->
              <hr class="mt-4" />
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <h6 class="heading-small text-muted mb-1">Order Status</h6>
              <hr class="my-1" />
              <div class="flex-container  py-1">
                <div style="flex-grow: 1;">Order ID</div>
                <div style="text-align: right;padding: 2px;border-radius: 5px;"><b>{{ model.orderID }}</b></div>

              </div>
              <div class="flex-container  py-1">
                <div style="flex-grow: 1;">Order Date</div>
                <div style="text-align: right;padding: 2px;border-radius: 5px;">{{ (model.date) ? model.date.toDate().toLocaleString() : '-'}}</div>

              </div>
              <div class="flex-container  py-1">
                <div style="flex-grow: 1;">Payment Status</div>
                <div style="text-align: right;padding: 2px;border-radius: 5px;" :class="paymentStatusStyle">{{ (model.paymentStatus || "Unpaid") }}</div>

              </div>
              <div class="flex-container">
                <div style="flex-grow: 1;">Order Status</div>
                <div style="text-align: right;padding: 2px;border-radius: 5px;" :class="orderStatusStyle">{{ (model.orderStatus || "Unfulfilled") }}</div>
              </div>
              <div class="flex-container">
                <div style="flex-grow: 1;">Payment Method</div>
                <div style="text-align: right;padding: 2px;border-radius: 5px;">{{model.selectedPaymentMethod || "Bank Transfer"}}</div>
              </div>
              <div class="flex-container" v-if="model.selectedPaymentMethod == 'xendit'">
                <div style="flex-grow: 1;">Payment Link (Xendit)</div>
                <div style="text-align: right;padding: 2px;border-radius: 5px;"><div @click="clipboard(invoice_url)" class="btn btn-primary btn-sm" v-if="invoice_url">Copy Link</div><span v-else>"ERROR"</span></div>
              </div>

              <hr class="my-1" />
              <h6 class="heading-small text-muted mb-1 mt-4">Payment</h6>
              <hr class="my-1" />

              <div class="flex-container">
                <div style="flex-grow: 1;">Subtotal <span style="color:darkgray">{{ model.cart.length }} item(s)</span></div>
                <div style="text-align: right;">{{model.totalBeforeDiscount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'IDR',
                  })
                }}
                </div>
              </div>
              <div class="flex-container" v-if='model.discount'>
                <div style="flex-grow: 1;">Discount <span style="color:darkgray">(Voucher Code: {{ model.voucherCode }})</span></div>
                <div style="text-align: right;">{{(model.discount).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'IDR',
                })
              }}</div>
              </div>
              <div class="flex-container">
                <div style="flex-grow: 1;">Shipping</div>
                <div style="text-align: right;">{{(model.selectedCourier.price).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'IDR',
                })
              }}</div>
              </div>
              <hr class="my-3" />
              <div class="flex-container">
                <div style="flex-grow: 1;"><b>Total</b></div>
                <div style="text-align: right;"><b>{{(model.total).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'IDR',
                })
              }}</b></div>
        </div>
        
        <h6 class="heading-small text-muted mb-1 mt-4">Contact information</h6>
        <hr class="my-1" />
        <small>

        <div>{{ model.name }}</div>
        <div>{{ model.phoneNumber }}</div>
        <div>{{ model.email }}</div>
      </small>
        
        <h6 class="heading-small text-muted mb-1 mt-4">Shipping Address</h6>
        <hr class="my-1" />
        <small>
          <b>{{ model.address }}</b>
          <div>Kecamatan: {{ model.subdistrict_name }}</div>
          <div>Kota/Kab.: {{ model.city_name }}</div>
          <div>Provinsi: {{ model.province_name }}</div>        
        </small>        
        
        <!-- <pre class="card mt-3" v-if="shipping_data">{{ shipping_data }}</pre> -->
      </div>

    </div>
        </div>
        <!-- <div class="blog-actions">
          <button class="btn btn-sm btn-primary" @click="updateOrder">Save Changes</button>
          <router-link class="router-button" :to="{ name: 'BlogPreview' }">Preview Changes</router-link>
        </div> -->
      </form>



    </card>
  </div>
</div>
</template>

<script>
import "firebase/storage";
import firebaseApp from "../firebase/firebaseInit";

const db = firebaseApp.firestore();
export default {
  name: "CreatePost",
  data() {
    return {
      id: null,
      file: null,
      error: null,
      errorMsg: null,
      loading: null,
      showMenu: false,
      invoice_url: '',
      model: {
        cart: [],
        name: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: 'Indonesia',
        zipCode: '',
        useCoupon: false,
        couponVerified: false,
        couponCode: '',
        total: 0,
        discount: 0,
        totalBeforeDiscount: 0,
        paymentStatus: 'Unpaid',
        selectedPaymentMethod: 'Bank Transfer',
        orderStatus: 'Unfulfilled',
        selectedCourier: {
          price: 0
        }
      },
      productName: "",
      shipping_data: '',
    };
  },
  async mounted() {
    this.loading = true;
    this.routeID = this.$route.params.id;
    this.getProduct();
  },
  methods: {
    toggleMenu(){
      this.showMenu = !this.showMenu;
    },



    clipboard(invoice_url){
      navigator.clipboard.writeText(invoice_url).then(() => {
        alert('link has been copied: '+invoice_url);
      })
      .catch(() => {
        alert("something went wrong");
      });
    },
    
    async getProduct() {
      const thisVue = this;
      await db.collection("orders").doc(this.routeID).get()
      .then(snapshot => {
        console.log(snapshot.data());
        thisVue.model = snapshot.data();
        if(snapshot.data().selectedPaymentMethod == 'xendit')
          if(snapshot.data().xendit)
            if(snapshot.data().xendit.invoice_url)
              this.invoice_url = snapshot.data().xendit.invoice_url;
        this.loading = false;
      });
    },
    
    async updateOrder() {
      const thisVue = this;
      thisVue.toggleMenu();
      
      if(this.model.orderStatus == 'Unfulfilled' || this.model.orderStatus == null)
        this.model.orderStatus = 'Fulfilled';
      else
        this.model.orderStatus = 'Unfulfilled'
      
      await db.collection("orders").doc(this.routeID).update({orderStatus: thisVue.model.orderStatus})
      .then(res => {
        console.log(res);
        alert('the order has been updated to '+thisVue.model.orderStatus);
      });
    },

    async updatePayment() {
      const thisVue = this;
      thisVue.toggleMenu();
      
      if(this.model.paymentStatus == 'Unpaid' || this.model.paymentStatus == null)
        this.model.paymentStatus = 'Paid';
      else
        this.model.paymentStatus = 'Unpaid'
      
      let isByme = false;
      this.model.cart.forEach((product) => {
        // console.log("check product.BymeCode", product.BymeCode);
        if(!product.BymeCode && (product.productName === 'Byme Kit' || product.productName === 'Byme Kit Digital')){
          product.BymeCode = this.model.orderID.substring(1,4) + Math.random().toString(36).slice(7).toUpperCase();
          isByme = true;
        }
      })
      if(isByme){
        await db.collection("orders").doc(this.routeID).update({paymentStatus: thisVue.model.paymentStatus, isByme: isByme, cart: this.model.cart})
        .then(res => {
          console.log(res);
          alert('the order has been updated to '+thisVue.model.paymentStatus);
        });
      
      } else {
        await db.collection("orders").doc(this.routeID).update({paymentStatus: thisVue.model.paymentStatus})
        .then(res => {
          console.log(res);
          alert('the order has been updated to '+thisVue.model.paymentStatus);
        });
      }
    },

    async deleteModel() {
        if (confirm("This action can't be undone, if you want to remove the book from customer display, you can update the status to archived instead. Are you sure you want to delete this product?") == false) {
          console.log("User cancelled the prompt.");
        } else {
          this.loading = true;
          await db.collection("orders").doc(this.routeID).update({void: true}).then(
            () => {
              alert('your product has sucessfully been deleted, you will be redirected to orders list page');
              this.$router.push({ name: "orders"});
            }
          );
        }
      },
    async getToken(){
        const doc = await db.collection("shipdeo").doc("token").get();
        if (!doc.exists) {
            console.log('No such document!');
            alert('No Token Found!');
        } else {
            // console.log('Document data:', doc.data());
            return doc.data().token
        }
    },
    async checkShipping(showAlert){
      const token = await this.getToken();
      const shipdeo_url = 'https://main-api-production.shipdeo.com/v1';
      const endpoint = '/couriers/orders';
      const itemsName = this.model.cart[0].productName;
      const options = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+token,
                    'Content-Type': 'application/json'
                },
                url: shipdeo_url+endpoint+`?itemsName=${encodeURIComponent(itemsName)}`,
                method: 'get',
      };
      
      const response = await this.$http(options);
      if(response.status == 200){
          console.log('data received and checking data ', response.data);
          let shipping_data = '';
          response.data.data.list.forEach((order) => {
            if(order.order_number == this.model.orderID){
              // alert('found order '+this.model.orderID);

              if(shipping_data == [] || order.status != 8)
                shipping_data = order
              console.log('order.status',order.status, order);
            }
          });
          console.log('order.selected',shipping_data);
          
          if(shipping_data){
            if(shipping_data.order_histories.length>0){
              const latest_order_status = this.orderHistoryStatus(shipping_data.order_histories[shipping_data.order_histories.length-1].status);
              if(showAlert) alert('Latest order status is : '+latest_order_status);
              db.collection("orders").doc(this.model.orderID).update({shipping_data: shipping_data, orderStatus: latest_order_status}); 
              this.model.orderStatus = latest_order_status;
            } else {
              db.collection("orders").doc(this.model.orderID).update({shipping_data: shipping_data}); 
            }
            this.shipping_data = shipping_data;
            this.model.shipping_data = this.shipping_data;
          } else {
            if(showAlert) alert('No shipping found.');
          }
      } else {
          alert('There is error in shipping data, please contact our Customer Service WhatsApp in +62-888-0185-0011');
          console.log('shipping response error', response);
      }  
    },
    async createShippingOrder(){
      await this.checkShipping(false);
      
      console.log(this.model.orderID);
      if(this.model.shipping_data){
        alert('shipping data already found');
        this.shipping_data = this.model.shipping_data;
      }
      else {
          const token = await this.getToken();

          const shipdeo_url = 'https://main-api-production.shipdeo.com/v1';
          const endpoint = '/couriers/orders';

          const items = [];
          this.model.cart.forEach(product => {
            items.push({
                      "name": product.productName,
                      "description": product.productName,
                      "weight": (product.productWeight) ? product.productWeight : '200',
                      "weight_uom": "gr",
                      "qty": 1,
                      "value": product.productPrice,
                      "width": 20,
                      "height": 20,
                      "length": 10,
                      "dimension_uom": "cm",
                      "total_value": product.productPrice
                    });
          });

          const options = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+token,
                    'Content-Type': 'application/json'
                },
                url: shipdeo_url+endpoint,
                method: 'post',
                data: {
                  "courier": this.model.selectedCourier.courierCode,
                  "courier_service": this.model.selectedCourier.service,
                  "order_number": this.model.orderID,
                  "is_cod": false,
                  "delivery_type": "pickup",
                  "delivery_time": "07/28/2023 02:00:00 PM +07:00",
                  "is_send_company": true,
                  "origin_lat": "-6.223749735697065",
                  "origin_long": "106.66198662463152",
                  "origin_province_code": "36",
                  "origin_province_name": "Banten",
                  "origin_city_code": "36.71",
                  "origin_city_name": "Kota Tangerang",
                  "origin_subdistrict_code": "36.71.11",
                  "origin_subdistrict_name": "Pinang",
                  "origin_postal_code": "15144",
                  "origin_contact_name": "Bookabook",
                  "origin_contact_phone": "088801850011",
                  "origin_contact_address": "Jalan Jalur Sutera Timur A1-17 Kunciran, Alam Sutera",
                  "origin_contact_email": "hello@bookabook.id",
                  "origin_note": "",

                  "destination_lat": null,
                  "destination_long": null,

                  "destination_province_code": this.model.province_code,
                  "destination_province_name": this.model.province_name,
                  "destination_city_code": this.model.city_code,
                  "destination_city_name": this.model.city_name,
                  "destination_subdistrict_code": this.model.subdistrict_code,
                  "destination_subdistrict_name": this.model.subdistrict_name,
                  "destination_postal_code": this.model.zipCode,
                  "destination_contact_name": this.model.name,
                  "destination_contact_phone": this.model.phoneNumber,
                  "destination_contact_address": this.model.address,
                  "destination_note": "",
                  "delivery_note": "",
                  "items": items,
                  "transaction": {
                    "subtotal": this.model.totalBeforeDiscount,
                    "shipping_charge": this.model.selectedCourier.price,
                    "fee_insurance": 0,
                    "is_insuranced": false,
                    "discount": this.model.discount,
                    "total_value": this.model.totalBeforeDiscount+this.model.selectedCourier.price-this.model.discount,
                    "total_cod": 0,
                    "weight": 1,
                    "width": 20,
                    "height": 20,
                    "length": 10,
                    "coolie": 1,
                    "package_category": "NORMAL",
                    "package_content": "Bookabook Product"
                  }
                },
            };

            const thisVue = this;
            this.$http(options)
                .then(function (response) {
                    if(response.status == 200){
                        console.log('data received and updating data ',response.data);
                        thisVue.shipping_data = response.data.data;
                        db.collection("orders").doc(this.model.orderID).update({shipping_data: response.data.data});
                        thisVue.model.shipping_data = thisVue.shipping_data;
                    } else {
                        alert('There is error in shipping data, please contact our Customer Service WhatsApp in +62-888-0185-0011');
                        console.log('shipping response error', response);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
      }
    },
    async getShippingLabel(){
      await this.checkShipping(false);
      
      if(this.model.shipping_label_url){
        // alert('open '+ this.model.shipping_label_url)
        window.open(this.model.shipping_label_url, '_blank');
      } else if(!this.model.shipping_data._id){
        alert('shipping data not found');
      }
      else {
        
          const token = await this.getToken();

          const shipdeo_url = 'https://main-api-production.shipdeo.com/v1';
          const endpoint = '/shipping-label';

          const options = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+token,
                    'Content-Type': 'application/json'
                },
                url: shipdeo_url+endpoint,
                method: 'post',
                data: {
                  "ordersId": [ this.model.shipping_data._id ],
                  "display_type": "string",
                  "showField": {
                    "orderDetail": true,
                    "senderAddress": true,
                    "tenantLogo": true,
                    "showBarcodeNoOrder": true,
                    "showBarcodeTrackingNumber": true,
                    "showCreatedDateRelated": true,
                    "showShippingCharge": true
                  },
                  "config": {},
                  "additional_info": {},
                  "return_link_only": true
                },
            };

            const thisVue = this;
            this.$http(options)
                .then(function (response) {
                    if(response.status == 200){
                        // console.log('data received and updating data ',response.data);
                        db.collection("orders").doc(thisVue.model.orderID).update({shipping_label_url: response.data.content});
                        thisVue.model.shipping_label_url = response.data.content;
                        window.open(thisVue.model.shipping_label_url, '_blank');
                    } else {
                        alert('There is error in shipping data, please contact our Customer Service WhatsApp in +62-888-0185-0011');
                        console.log('shipping response error', response);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
      }
    },
    async requestPickup(delivery_type){
      await this.checkShipping(false);
      
      if(!this.model.shipping_data._id){
        alert('shipping data not found');
      }
      else {
        
          const token = await this.getToken();

          const shipdeo_url = 'https://main-api-production.shipdeo.com/v1';
          const endpoint = '/couriers/orders';

          const options = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+token,
                    'Content-Type': 'application/json'
                },
                url: shipdeo_url+endpoint+'/'+this.model.shipping_data._id,
                method: 'patch',
                data: {
                  "delivery_type": delivery_type,
                },
            };

            const thisVue = this;
            this.$http(options)
                .then(function (response) {
                    if(response.status == 200){
                        // console.log('data received and updating data ',response.data);
                        thisVue.shipping_data = response.data.data;
                        db.collection("orders").doc(this.model.orderID).update({shipping_data: response.data.data});
                        thisVue.model.shipping_data = thisVue.shipping_data;
                    } else {
                        alert('There is error in shipping data, please contact our Customer Service WhatsApp in +62-888-0185-0011');
                        console.log('shipping response error', response);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
      }
    },
    orderHistoryStatus(status){
      switch(status){
        case 1: return "ENTRY";
        case 2: return "CONFIRMED";
        case 3: return "PICKED";
        case 4: return "CONFIRM_PROBLEM";
        case 5: return "ON_PROGRESS";
        case 6: return "DELIVERED";
        case 7: return "CANCEL_REQUEST";
        case 8: return "CANCELLED";
        case 9: return "ON_HOLD";
        case 11: return "PROBLEM";
        case 12: return "RETURNED";
        case 15: return "RETURNING";
        case 13: return "LOST_BROKEN";
        case 14: return "CONFIRM_CANCELLED";
        case 16: return "WAITING_FOR_CONFIRMATION";
        case 17: return "FAILED_DELIVERY_1";
        case 18: return "FAILED_DELIVERY_1";
        case 19: return "FAILED_DELIVERY_1";
        case 20: return "RETRY_DELIVERING";
        case 99: return "NO_UPDATE_48";
        case 98: return "NO_UPDATE_72";
        case 0: return "OTHER";
        default: return "NOT FOUND";
      }
    }
  },
  computed: {
    orderStatusStyle() {
      return {
        'text-white': true,
        'bg-warning': this.model.orderStatus == 'Unfulfilled' || this.model.orderStatus == null,
        'bg-success': this.model.orderStatus == 'Fulfilled' || this.model.orderStatus,
      }
    },
    paymentStatusStyle() {
      return {
        'text-white': true,
        'bg-warning': this.model.paymentStatus == 'Unpaid' || this.model.paymentStatus == null,
        'bg-success': this.model.paymentStatus == 'Paid',
      }
    },
  },
};
</script>

<style lang="scss">

.unfulfilled{ color:blue }
.unpaid{ color:red }

.item.flex-container {
  margin:10px 0px;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.flex-left{
  flex:10%;
  display: flex;
}

.flex-mid{
  flex:55%;
}


.flex-right{
  flex:10%;
  display: flex;
}

.image-viewer{
  background-size: cover; background-position: center top;
  background-repeat: no-repeat;
  flex-shrink: 0; align-self: center;
}
</style>
