<template>
    <div :class="routeURL" class="personalizedbook row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="card bg-secondary shadow border-0">
                <div class="card-header bg-transparent">
                    <div class="row flex">
                        <div class="col-md-6 flex images-container" v-if="product.images">
                            <div class="product-thumbnail-container">
                                <div class="product-thumbnail" v-for="image, index in product.images" :key="index" @click="selectIndex(index)"
                                    :style="{ backgroundImage: 'url(' + product.images[index] + ')' }">
                                </div>
                            </div>
                            <div class="product"
                                :style="{ backgroundImage: 'url(' + product.images[selectedIndex] + ')' }">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h1 class="pt-3 text-dark"><b>{{ product.name }}</b></h1>
                            <div class="pb-3 text-dark" v-if="product.price != 0">
                                <span :class="{'text-danger': product.priceBeforeDiscount ? true : false }">{{ parseInt(product.price).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'IDR',
                                }) }}</span>
                            <span style="padding-left: 10px; text-decoration: line-through;" v-if="product.priceBeforeDiscount">{{ parseInt(product.priceBeforeDiscount).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'IDR',
                                }) }}</span>
                            </div>
                            <base-button class="mt-1 mb-5" type="primary" @click="modals.modal = true">Personalize My Book</base-button>
                            <p v-html="product.description"></p>
                        </div>
                    </div>
                    
                    <form @submit.prevent>
                        <hr class="my-4" />
                        <h6 class="heading-small text-muted mb-4">User information</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                                label="Child Name (max. 9 letters)"
                                                placeholder="Child Name"
                                                input-classes="form-control-alternative"
                                                v-model="model.childname"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="pl-lg-4 pb-4 no_child_hider">
                            <label class="form-control-label"> Select your child appearance</label>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div v-for="(selected_pic, selected_index) in selections"
                                        v-bind:key="selected_index"
                                        class="card child_selections"
                                        :class=" { selected: (model.selected_child_index == selected_index) }"
                                        @click="selectChild(selected_index)">
                                        <img :src="selected_pic">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl-lg-4">
                            <div class="form-group">
                                <base-input alternative=""
                                            label="Personal Message">
                                    <textarea v-model="model.message" rows="4" class="form-control form-control-alternative" placeholder="A few words about your dear child ..."></textarea>
                                </base-input>
                            </div>
                        </div>


                        <div class="container scrollable">
                            <div class="scrollable-window">
                                <div class="bookviews" :style="{backgroundImage: 'url(/img/'+routeURL+'/basecover-front.jpg)'}">
                                    <div class="filler"></div>
                                    <div class="book-childname scrollable-item">
                                        <h2 class="customfont text-center">
                                            <span v-for="(letter, index) in (model.childname)" :key="letter" :style="{color: (index%4==0) ? '#005D94' :
                                                                                                                            (index%4==1) ? '#F79422' :
                                                                                                                            (index%4==2) ? '#1A7C3E' :
                                                                                                                            '#C33C27',
                                                                                                                        fontSize: (model.childname.length>7) ? '0.8em' : '1em'
                                                                                                                        }">{{ letter }}</span>
                                        </h2>                                    </div>
                                    <div v-for="(selected_pic, selected_index) in selections"
                                        v-bind:key="selected_index"
                                        class="child_view child_selections"
                                        :class=" { selected: (model.selected_child_index == selected_index) }"
                                        @click="selectChild(selected_index)">
                                        <img :src="selected_pic">
                                    </div>
                                    <div class="book-childname2 scrollable-item">
                                        <h2 class="customfont text-center">
                                            <span v-for="(letter, index) in (model.childname)" :key="letter" :style="{color: (index%4==0) ? '#005D94' :
                                                                                                                            (index%4==1) ? '#F79422' :
                                                                                                                            (index%4==2) ? '#1A7C3E' :
                                                                                                                            '#C33C27',
                                                                                                                        fontSize: (model.childname.length>7) ? '0.8em' : '1em'
                                                                                                                        }">{{ letter }}</span>
                                        </h2>
                                    </div>
                                </div>
                                <div class="bookviews" :style="{backgroundImage: 'url(/img/'+routeURL+'/basecover-back.jpg)'}">
                                    <div class="filler"></div>
                                    <h5 class="customfont book-message scrollable-item">
                                        {{ model.message }}
                                    </h5>
                                    <div v-for="(selected_pic, selected_index) in selections2"
                                        v-bind:key="selected_index"
                                        class="child_view child_selections child_selections2"
                                        :class=" { selected: (model.selected_child_index == selected_index) }"
                                        @click="selectChild(selected_index)">
                                        <img :src="selected_pic">
                                    </div>
                                    <!-- <div class="book-childname3 scrollable-item">
                                        <h2 class="customfont text-center">
                                            <span v-for="(letter, index) in (model.childname)" :key="letter" :style="{color: (index%4==0) ? '#005D94' :
                                                                                                                            (index%4==1) ? '#F79422' :
                                                                                                                            (index%4==2) ? '#1A7C3E' :
                                                                                                                            '#C33C27',
                                                                                                                        fontSize: (model.childname.length>7) ? '0.8em' : '1em'
                                                                                                                        }">{{ letter }}</span>
                                        </h2>
                                    </div> -->

                                </div>
                            </div>
                            
                        </div>


                        <hr class="my-4" />

                        <div class="btn btn-primary btn-checkout" @click="processCheckout()">
                            <span>Checkout</span>
                            <i class="ni ni-bold-right"></i>
                        </div>
                        <!-- Description -->
                    </form>
                    
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import firebaseApp from "@/firebase/firebaseInit";

const db = firebaseApp.firestore();
export default {
    name: 'bookabook',
    data() {
        return {
            routeURL: '',
            loading: true,
            product: {
                name: '',
                url: '',
            },
            selections: [],
            selectedIndex: '',
            selections2: [],
            model: {
                childname: 'Erland',
                selected_child_index: 0,
                selected_child: "/img/kidandthedragon/B1.png",
                message: "At the end of the day you can be anything! Just follow your heart and you will find a way!",
                productName: 'Kid And The Dragon',
                productPrice: 0,
                productUrlCode: ''
            }
        }
    },
    mounted() {
        this.loading = true;
        this.routeURL = this.$route.params.id;
        this.selections = [
                            '/img/'+this.routeURL+'/B1.png',
                            '/img/'+this.routeURL+'/B2.png',
                            '/img/'+this.routeURL+'/B3.png',
                            '/img/'+this.routeURL+'/G1.png',
                            '/img/'+this.routeURL+'/G2.png',                    
                            '/img/'+this.routeURL+'/G3.png',
        ];
        this.selections2 = [
                            '/img/'+this.routeURL+'/B1-back.png',
                            '/img/'+this.routeURL+'/B2-back.png',
                            '/img/'+this.routeURL+'/B3-back.png',
                            '/img/'+this.routeURL+'/G1-back.png',
                            '/img/'+this.routeURL+'/G2-back.png',                    
                            '/img/'+this.routeURL+'/G3-back.png',
                        ];
        this.getProduct();
    },
    methods: {
        selectIndex(index){
            this.selectedIndex = index;
        },
        async getProduct() {
            const thisVue = this;
            await db.collection("products").where('url', '==', this.routeURL).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    alert('No matching product, error occured.');
                    return;
                }  
                snapshot.forEach(doc => {
                    const product = doc.data();
                    console.log(doc.id, '=>', product);
                    thisVue.product = doc.data();
                    thisVue.model.productName = product.name;
                    thisVue.model.productPrice = parseInt(product.price);
                    thisVue.model.productPriceBeforeDiscount = parseInt(product.priceBeforeDiscount);
                    thisVue.model.productUrlCode = product.url;
                    thisVue.model.productImage = product.img;
                    thisVue.model.selected_child = thisVue.selections[thisVue.model.selected_child_index];
                });
                this.loading = false;
            });
        },
  
        selectChild(selected_index) {
            this.model.selected_child_index = selected_index;
            this.model.selected_child = this.selections[this.model.selected_child_index];
        },
        processCheckout(){
            if(this.model.productUrlCode=='' || this.model.productPrice==0){
                alert('error occured, please contact web administrator');
            } else {
                this.cartModel.push(this.model);
                this.$store.commit("setCartState", this.cartModel);
                this.$router.push({ name: "checkout"});
            }
        }
    },
    computed: {
        cartModel() {
          return this.$store.state.cart;
        }
    },
}
</script>