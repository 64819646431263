<template>
  <div class="row py-4">
    <div class="col-12 col-md-8">
      <!-- CONTENT -->
      <section class="mb-5">
        <h1 class="post-title">
          {{ blog.title }}
        </h1>
        
        <div class="mb-3">
          <img
            :src="blog.image"
            width="100%"
          >
        </div>
        <div v-html="blog.content">
        </div>
      </section>

      <!-- <section class="mb-4">
        <h2 class="text-blue font-outfit">COMMENT</h2>
        <hr class="mt-1 mb-4">
        <div class="mb-4">
          <div
            v-for="(comment, index) in comments"
            :key="index"
          >
            <div class="row">
              <div class="col-auto">
                <img
                  src="/img/icons/profile.png"
                >
              </div>
              <div class="col-auto">
                <p class="mb-2">by {{  comment.author }} on {{ comment.date }}</p>
                <p>{{ comment.comment }}</p>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-blue font-outfit">LEAVE A COMMENT</h2>
        <hr class="mt-1 mb-4">
        <form @submit.prevent>
          <base-input
            placeholder="Name"
            addonLeftIcon="ni ni-single-02"
          />
          <base-input
            placeholder="Email"
            addonLeftIcon="fa fa-envelope"
          />
          <textarea
            placeholder="Write a comment"
            class="px-2 py-1 w-100 rounded mb-4 textarea"
          >
          </textarea>
          <base-button
            type="submit"
          >
            Post Comment
          </base-button>
        </form>
      </section> -->

      <section>
        <h2 class="text-blue font-outfit">RELATED ARTICLES</h2>
        <hr class="mt-1 mb-4">
        <div class="row">
          <div
            v-for="(article, index) in blogs"
            :key="index"
            class="col-4 my-2"
          >
            <router-link :to="`/blogs/${article.code}`">
              <div @click="triggerUrlUpdate(article.code)">
                <img
                  :src="article.image"
                  class="mb-1 w-100"
                  style="aspect-ratio: 1; object-fit: cover;"
                >
                <span class="text-black">
                  {{ article.title }}
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </section>
    </div>

    <aside class="col-12 col-md-4 py-5">
      <!-- <div class="mb-4">
        <input
          type="text"
          class="border border-gray"
        >
        <button
          class="bg-blue text-white border-0"
        >
          Search
        </button>
      </div> -->
      <div v-if="blogs.length" class="mb-4">
        <p class="h3 text-blue">
          <strong>Recent Posts</strong>
        </p>
        <p
          v-for="(post, index) in blogs"
          :key="index"
          class="mb-2"
        >
          <router-link :to="`/blogs/${post.code}`">
            <span class="text-black" @click="triggerUrlUpdate(post.code)">{{ post.title }}</span>
          </router-link>
        </p>
      </div>
      <!-- <div>
        <p class="h3 text-blue">
          <strong>Get Updates in Your Inbox</strong>
        </p>
        <base-input
          placeholder="Enter your email"
          class="mb-3"
        />
        <base-button type="primary">Subscribe</base-button>
      </div> -->
    </aside>
  </div>
</template>

<script>
import firebaseApp from "../../firebase/firebaseInit";
const db = firebaseApp.firestore();

export default {
  created() {
    this.routeID = this.$route.params.id;
  },
  computed: {
    blog() {
      return this.blogs.filter(obj => {
                return obj.code === this.routeID;
                })[0] || {url:'', title:'', excerpt:'',image:''}
    },
  },
  mounted(){
      this.loadModel();
  },
  methods: {
    triggerUrlUpdate(routeID){
      this.routeID = routeID;
    },
    async loadModel(){
        this.blogs = [];
        const dataBase = db.collection('blogs').orderBy("_updated_at", "desc");
        const dbResults = await dataBase.get();
        console.log('dbResults', dbResults)
        dbResults.forEach((doc) => {
          const data = doc.data();
            data.id = doc.id;
            console.log(data);
            this.blogs.push(data);
        });
        this.modelLoaded = true;

      }
  },
  data: () => ({
    routeID: '',
    blogs: [
      {
        url: 'tata-cara-masuk-perpus-jakarta-untuk-karya-raya-2023',
        title: 'Tata Cara Masuk Perpus Jakarta Untuk Karya Raya 2023',
        image: '/img/blogs/poster-karya-raya-2023-FA.jpg',
        createdAt: 'Oct 20, 2023',
        author: 'Ernest Junius',
        excerpt: `<h2>Tata Cara Masuk Perpus Jakarta Untuk Karya Raya 2023</h2>

<ol>
	<li>Pengunjung wajib daftar online terlebih dahulu melalui link perpustakaan.jakarta.go.id.</li>
	<li>Jika sudah reservasi online, pengunjung bisa masuk ke kawasan Perpustakaan lalu menuju ke lantai 2 Gedung Panjang, naik eskalator ke lantai 3.</li>
	<li>Pengunjung akan diminta check in dengan cara menunjukkan QR Barcode yang sudah direservasi online.</li>
	<li>Pengunjung harus menitipkan tas dan diperbolehkan membawa barang penting seperti dompet, gadget, dan alat tulis.</li>
	<li>Pengunjung bisa menggunakan tas transparan yang didapatkan dari petugas apabila membawa barang banyak.</li>
	<li>Saat selesai, pengunjung bisa langsung menuju area pameran di lantai 4.</li>
</ol>

<p>&nbsp;</p>

<h2>Cara Daftar Online</h2>

<ol>
	<li>Kunjungi website perpustakaan.jakarta.go.id atau download aplikasi Jaklitera.</li>
	<li>Jika belum terdaftar sebagai anggota maka daftarkan diri dulu dengan klik &lsquo;Register&rsquo;.<br />
	<img alt="" src="/img/blogs/karyaraya-ss-pendaftaran.jpeg" style="height:540px; width:300px" /><br />
	&nbsp;</li>
	<li>Isi data diri berupa NIK, Nama Lengkap, Nomor Whatsapp, User Name, Email, dan kata Sandi.</li>
	<li>Klik &lsquo;Selanjutnya&rsquo; untuk memperoleh kode OTP melalui email. Lalu konfirmasi kode OTP melalui tautan di email<br />
	<img alt="" src="/img/blogs/karyaraya-ss-otp.jpeg" style="height:540px; width:300px" /><br />
	&nbsp;</li>
	<li>Selanjutnya, verifikasi data diri dengan cara unggah foto diri dengan format close-up, lalu klik &lsquo;Lengkapi Data Diri&rsquo;.</li>
	<li>Untuk warga yang memiliki KTP luar DKI Jakarta wajib mengunggah KTP.</li>
	<li>Isi alamat lengkap sesuai KTP, lalu klik &lsquo;Selesai&rsquo;.</li>
	<li>Jika pendaftaran berhasil, status TERVERIFIKASI akan terkirim melalui email.</li>
</ol>

`,
        commentsCount: 0
      },
      {
        url: 'giveaway-30-buku-untuk-30-hari-kebaikan-ramadan',
        title: 'Giveaway 30 Buku Untuk 30 Hari Kebaikan Ramadan!',
        image: '/img/blogs/Giveaway_110422-02_small_77118b34-c70a-40b4-a858-378d725547eb_1024x1024.webp',
        createdAt: 'April 11, 2022',
        author: 'Ernest Junius',
        excerpt: 'Tidak terasa sudah seminggu lebih memasuki bulan Ramadan! @bookabook.id ada giveaway yang berupa: 30 buku anak untuk 30 orang pemenang (3 buku personalized dan 27 buku sticker non-personalized).',
        commentsCount: 0
      },
      {
        url: 'yuk-bermain-kata-dengan-polindrom',
        title: 'Yuk bermain kata dengan palindrom',
        image: '/img/blogs/palindrom-09_1024x1024.webp',
        createdAt: 'March 19, 2022',
        author: 'Ernest Junius',
        excerpt: 'Apakah itu palindrom? Palindrom (atau palindrome dalam bahasa Inggris) adalah sebuah kata, frasa, atau kalimat yang sama persis ketika dibaca dari depan maupun dari belakang, seperti kata ini, “kodok” atau kalimat ini, “Ibu Ratna antar ubi.”',
        commentsCount: 0
      },
      {
        url: 'macan-dan-kelinci-hutan',
        title: 'Macan Dan Kelinci Hutan',
        image: '/img/blogs/IMG_1821_1024x1024.webp',
        createdAt: 'March 19, 2022',
        author: 'Ernest Junius',
        excerpt: 'A tiger found a hare sleeping near a tree, and was just going to devour her when he caught sight of a passing stag...',
        commentsCount: 0
      },
      {
        url: 'singa-beruang-dan-rubah',
        title: 'Singa Beruang Dan Rubah',
        image: '/img/blogs/FT_01-02_1024x1024.webp',
        createdAt: 'March 18, 2022',
        author: 'Ernest Junius',
        excerpt: 'A lion and a bear were fighting for a piece of meat, which they had both seized at the same moment. The battle was long and fierce, and at length both of them were exhausted, and lay upon the ground gasping for breath. A fox had all the time prowling round and watching the fight...',
        commentsCount: 0
      },
    ],
    recent: [
      { title: 'Macan dan Kelinci Hutan', url: 'macan-dan-kelinci-hutan' },
      { title: 'Singa, Beruang dan Rubah', url: 'singa-beruang-dan-rubah' },
      { title: 'Yuk bermain kata dengan palindrom', url: 'yuk-bermain-kata-dengan-polindrom' },
      { title: 'Giveaway 30 Buku Untuk 30 Hari Kebaikan Ramadan!', url: 'giveaway-30-buku-untuk-30-hari-kebaikan-ramadan' }
    ],
    comments: [
      {
        author: 'Wasipah',
        date: 'April 14, 2022',
        comment: 'Masya Allah, semoga bertambah keberkahan'
      }
    ],
  })
}
</script>

<style>
.post-title {
  font-family: 'Outfit';
}

.textarea {
  border: 1px solid #cad1d7;
}

.textarea::placeholder {
  font-size: 14px;
  color: #8898aa;
}

.font-outfit {
  font-family: Outfit;
}
</style>