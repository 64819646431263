<template>
    <div>
        <base-header type="gradient-success" class="pb-2 pt-3 pt-md-6">
            <!-- Card stats -->
            <div class="row">
              
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt-3 b">
          <h2>Banner Setting</h2>
          <div class="row">
            <div v-if="imgUrl!=null">                     
              <div class="flex text-center my-3">
                <img class="preview" :src="imgUrl">
              </div>
            </div>   
            <div class="my-2">
              <div class="btn btn-primary" @click="click1">Choose Image</div>
              <input type="file" ref="input1"
              style="display: none"
              @change="previewImage" accept="image/*" >                
            </div>
              <!-- <div v-if="newImgUrl" class="btn btn-primary" color="pink" @click="updateBanner">Update Banner</div> -->
          </div>
            <!-- End charts-->

        </div>

    </div>
</template>
<script>
import firebase from "@/firebase/firebaseInit";
const db = firebase.firestore();

export default {
  data () {
    return {
      imageData: null,
      imgUrl: null,
      newImgUrl: null,
    }
  },
  created() {
    this.loadBanner();
  },
  methods: {
    loadBanner() {
      db.collection('home').doc('banner').get().then(result => {
        if(result.data())
          this.imgUrl = result.data().imgUrl;
        else
          console.log(result);
          // console.log(this.imgUrl);
      }).catch(err => {
        alert(err)
      })
    },

    click1() {
      this.$refs.input1.click()   
    },
    
    previewImage(event) {
      this.uploadValue=0;
      this.imgUrl=null;
      this.imageData = event.target.files[0];
      this.onUpload()
    },
    
    onUpload(){
      this.imgUrl=null;
      const storageRef=firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      },
      error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((imageUrl)=>{
          this.newImgUrl =imageUrl;
          this.imgUrl = imageUrl;
          // console.log(this.newImgUrl)
          this.updateBanner(this.newImgUrl)
        });
      }      
      );
    },
    updateBanner(imageUrl) {
      const banner = {
        imgUrl: imageUrl,
      } 
      db.collection('home').doc('banner').set(banner)
      .then(() => {
        console.log('upload success');
        alert('Your banner has been updated');
      })
      .catch(err => {
        alert(err)
      })
    },
  }
}
</script>

<style>
  .preview {
    width: 100%;
    height: auto;    
  }
</style>