<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <!-- <div class="card-header bg-transparent pb-5">
                        <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
                        <div class="btn-wrapper text-center">
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                                <span class="btn-inner--text">Github</span>
                            </a>
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                                <span class="btn-inner--text">Google</span>
                            </a>
                        </div>
                    </div> -->
                    <div class="card-body px-lg-5 py-lg-5 my-3">
                        <div class="text-center text-muted mb-4">
                            <small>Fill in your email, and we will send you a link to reset your password</small>
                            <!-- <small>Or sign in with credentials</small> -->
                        </div>
                        <form role="form">
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Email"
                                        addon-left-icon="ni ni-email-83"
                                        v-model="email">
                            </base-input>

                            <div class="text-center">
                                <base-button type="primary" class="my-4" @click.prevent="sendPasswordResetEmail">Send</base-button>
                            </div>
                            <div v-show="message" class="loading">{{ this.message }}</div>
                            <div v-show="error" class="error">{{ this.errorMessage }}</div>
                        </form>
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-6">
                        <router-link to="/login" class="text-dark"><small>&lt; Back to login</small></router-link>
                    </div>
                    <div class="col-6 text-right">
                        <router-link to="/register" class="text-dark"><small>Create new account</small></router-link>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import "firebase/auth";
import firebase from "firebase/app";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      message: "",
      error: null,
      errorMessage: "",
    };
  },
  methods: {
    sendPasswordResetEmail() {

        this.error = '';
        this.message = 'sending message, please wait...';

        firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
            this.message = 'password reset has been send to '+this.email+', please check your email (in inbox or spam folder), follow the steps and go back to login page.';
        })
        .catch((error) => {
            this.message = '';
            this.error = error.code;
            this.errorMessage = error.message;
        });
    },
  },
};
</script>
<style>
    .loading{
        text-align: center;
        font-size: small
    }
    .error {
        color: red;
        text-align: center;
        font-size: small
    }
</style>
