<template>
    <div>
        <div class="banner flex mt-3" style="width: 100%;">
            <div style="background-color: #F9A51A; width: 40%; height: 400px; padding:40px">
                <h3 style="color: white; text-align: center;">
                    <div><img src="/img/hero-accent.png" style="width: 350px;"></div>
                    <div><a href="https://byme.bookabook.id/"><img src="/img/hero-cta.png"
                                style="width: 350px; padding-top: 30px; padding-bottom: 5px;"></a></div>
                </h3>
            </div>
            <div class="hero" style="width: 1200px; max-width: 60%; height: 400px; background-size: cover;
                        background: url('/img/hero.JPG') center top no-repeat;">
            </div>
        </div>
        <!-- <div class="hero-banner pt--7" :style="{'backgroundImage': 'url('+bannerBgUrl+')'}">
            <div class="container py-7">
                <div class="col-md-6" style="padding-right:100px">
                    <h1 style="color:white">Create Your Own Personalized Books Now!</h1>
                    <p style="color:white">Create books with your child’s name and character in the story.</p>
                    <router-link to="/ourbooks" class="my-2">
                        <base-button type="primary" style="color:#05779F; background-color: #F9DE4F; border: 0;">Create your own book</base-button>
                    </router-link>
                </div>
            </div>
        </div> -->
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12">
                    <div class="bg-transparent text-center">
                        <content-placeholders v-show="products.length == 0" class="m-5">
                            <content-placeholders-heading :img="true" />
                            <content-placeholders-text :lines="2" />
                        </content-placeholders>
                        <div v-show="products.length > 0">
                            <h1 class="my-5">Our Best-Sellers</h1>
                            <div class="row my-1">
                                <div class="col-sm-12 col-md-4 my-2"><router-link to="/promoted-to-big-brother-sister"><img
                                            class="popular" src="/img/home/popular_3.jpg"></router-link></div>
                                <div class="col-sm-12 col-md-4 my-2"><router-link to="/kid-and-the-dragon"><img
                                            class="popular" src="/img/home/popular_2.jpg"></router-link></div>
                                <div class="col-sm-12 col-md-4 my-2"><router-link to="/custombook/all-the-things"><img
                                            class="popular" src="/img/home/popular_1.jpg"></router-link></div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-sm-12 col-md-3 flex proposition-flex">
                                    <div class="proposition py-2"><img src="/img/home/proposition_1.png"></div>
                                    <div class="proposition-text pl-3">
                                        <h3>5-Star rating</h3>
                                        <span>98% of our customers rated us 5 stars.</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 flex proposition-flex">
                                    <div class="proposition py-2"><img src="/img/home/proposition_2.png"></div>
                                    <div class="proposition-text pl-3">
                                        <h3>Loved by kids</h3>
                                        <span>Our books are loved by kids and parents.</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 flex proposition-flex">
                                    <div class="proposition py-2"><img src="/img/home/proposition_3.png"></div>
                                    <div class="proposition-text pl-3">
                                        <h3>Based on research</h3>
                                        <span>Our books are based on latest research in children education.</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 flex proposition-flex">
                                    <div class="proposition py-2"><img src="/img/home/proposition_4.png"></div>
                                    <div class="proposition-text pl-3">
                                        <h3>Data Protection</h3>
                                        <span>We protect and never share customers’ data.</span>
                                    </div>
                                </div>
                            </div>
                            <h1 class="my-5">Buying for a gift? Shop by age</h1>
                            <div class="row my-1">
                                <div class="col-sm-12 col-md-3 my-2"><router-link to="/ourbooks?age=0-3"><img class="fit"
                                            src="/img/home/byage_1.jpg"></router-link></div>
                                <div class="col-sm-12 col-md-3 my-2"><router-link to="/ourbooks?age=3-6"><img class="fit"
                                            src="/img/home/byage_2.jpg"></router-link></div>
                                <div class="col-sm-12 col-md-3 my-2"><router-link to="/ourbooks?age=6-9"><img class="fit"
                                            src="/img/home/byage_3.jpg"></router-link></div>
                                <div class="col-sm-12 col-md-3 my-2"><router-link to="/ourbooks?age="><img class="fit"
                                            src="/img/home/byage_4.jpg"></router-link></div>
                            </div>
                            <div class="row flex my-3" style="text-align:left">
                                <div><img class="illus" src="/img/home/illus_1.png"></div>
                                <div class="pl-3">
                                    <h1 class="py-3">We Want Every Child To Grow Up As A Passionate Reader</h1>
                                    <p>Since we started Bookabook.id in 2018, it has been our dream and long-term goal to
                                        make books that spark interest in children to love reading and grow up as passionate
                                        readers.</p>
                                    <p>Personalized books help children to relate and get excited about the story they are
                                        going to read. Most of their reaction would be, "It is really me in the book!" </p>
                                    <p>We hope that our personalized books can be the first step of their life-long reading
                                        journey!</p>
                                    <base-button type="primary">Read more about us</base-button>
                                </div>
                            </div>
                            <h1 class="my-5">Media, partners, and collaborations</h1>
                            <div class="row my-2 flex px-7">
                                <div class="col-sm-12 col-md-3 my-1"><img class="fit partners"
                                        src="/img/home/logo_smartmama.png"></div>
                                <div class="col-sm-12 col-md-3 my-1"><img class="fit partners"
                                        src="/img/home/logo_bebelac.png"></div>
                                <div class="col-sm-12 col-md-3 my-1"><img class="fit partners" src="/img/home/logo_lbi.png">
                                </div>
                                <div class="col-sm-12 col-md-3 my-1"><img class="fit partners"
                                        src="/img/home/logo_abbott.png"></div>
                            </div>
                            <h1 class="my-5">What our customers said about us!</h1>
                            <div class="row my-3" style="justify-content: space-around;">
                                <div class="col-md-12"><img class="fit" src="/img/home/testimony_v2.png"></div>
                                <!-- <div class="col-md-2 col-sm-4">
                                    <div class="testimony_card">
                                        <img src="/img/home/testimony_1.png" style="max-width:100%">
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4"><img src="/img/home/testimony_2.png" style="max-width:100%"></div>
                                <div class="col-md-2 col-sm-4"><img src="/img/home/testimony_3.png" style="max-width:100%"></div>
                                <div class="col-md-2 col-sm-4"><img src="/img/home/testimony_4.png" style="max-width:100%"></div>
                                <div class="col-md-2 col-sm-4"><img src="/img/home/testimony_5.png" style="max-width:100%"></div> -->
                            </div>
                            <div class="row flex my-3" style="text-align:left">
                                <div class="col-md-6"><img class="illus" src="/img/home/illus_2.png"></div>
                                <div class="col-md-6 pl-3">
                                    <h1 class="py-3">Subscribe for 10% OFF your first order</h1>
                                    <a target="_blank"
                                        href="https://web.whatsapp.com/send?text=Halo%20saya%20mau%20berlangganan%20buku%20bookabook%20dengan%20promo%20diskon%2010%%20dari%20website%20bookabook.id&phone=6288801850011">
                                        <base-button type="primary">Subscribe</base-button></a>
                                </div>
                            </div>
                            <!-- <h1 class="my-5">Don’t miss these deals!</h1>
                            <div class="row my-1">
                                <div class="col-sm-12 col-md-4 my-2"><router-link to="/yogyakarta"><img class="fit" src="/img/home/deals_1.jpg"></router-link></div>
                                <div class="col-sm-12 col-md-4 my-2"><router-link to="/custombook/the-clouds"><img class="fit" src="/img/home/deals_2.jpg"></router-link></div>
                                <div class="col-sm-12 col-md-4 my-2"><router-link to="/custombook/all-the-things"><img class="fit" src="/img/home/deals_3.jpg"></router-link></div>
                            </div> -->
                            <div class="row flex my-3" style="text-align:left">
                                <div class="pr-3">
                                    <h1 class="py-3">Give them a gift they will treasure for a lifetime.</h1>
                                    <router-link to="/ourbooks"><base-button type="primary">Create your personalized book
                                            now!</base-button></router-link>
                                </div>
                                <div><img class="illus" src="/img/home/illus_3.png"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from '../components/BaseButton.vue';
import firebase from "@/firebase/firebaseInit";
const db = firebase.firestore();

export default {
    components: { BaseButton },
    name: 'bookabook',
    data() {
        return {
            bannerBgUrl: '/img/home/hero_banner.jpg',
            bannerImgUrl: '/img/home/hero.jpg',
        }
    },
    created() {
        this.loadBanner();
    },
    computed: {
        products() {
            return this.$store.state.products;
        }
    },
    methods: {
        loadBanner() {
            db.collection('home').doc('banner').get().then(result => {
                if (result.data())
                    this.bannerImgUrl = result.data().imgUrl;
                else
                    console.log(result);
            }).catch(err => {
                alert(err)
            })
        },
        scrollToProducts() {
            this.$refs["products"].scrollIntoView({ behavior: "smooth" })
        },
        scrollToTop() {
            document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        }
    },
}

</script>
